
import React, { createContext, useContext, useState } from 'react';

// Create a context
const UserContext = createContext();

// Create a custom hook to use the context
export const useUserContext = () => useContext(UserContext);

export const UserProvider = ({ children }) => {
    const [users, setUsers] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState('');
    const [scrollPosition, setScrollPosition] = useState(0); // New state for scroll position

    return (
        <UserContext.Provider value={{ users, setUsers, selectedCategory, setSelectedCategory, scrollPosition, setScrollPosition }}>
            {children}
        </UserContext.Provider>
    );
};


