import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Card } from 'antd';
import '../style/topContributor.css';
import Header from './Header';




const TrendingUsers = () => {

    const [trendingUsers, setTrendingUsers] = useState([]);
    const [showTrending, setShowTrending] = useState(false);

    useEffect(() => {
        fetchTrendingDataUsers();

    }, []);

    const fetchTrendingDataUsers = async () => {
        try {
            const response = await axios.get('/api/trending/users');
            setTrendingUsers(response.data);

        } catch (error) {
            console.error('Error fetching trending data:', error);
        }
    };

    return (

        <>
            <Header />
            <div style={{ margin: '20px' }}>


                {(
                    <div style={{
                        display: 'flex', justifyContent: 'center', flexDirection: 'row',


                    }} className="trending-list">

                        <div className="trending-grid">
                            <h4>Top contributors</h4>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                {trendingUsers.map(user => (
                                    <Card key={user._id} className="custom-card"   >
                                        <div>{user.name}</div>
                                        <div>Score: {user.contributionScore}</div>
                                    </Card>
                                ))}
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>

    );
};

export default TrendingUsers;
