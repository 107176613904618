

import React from 'react';
import '../style/HowItWorks.css';
import Header from './Header';

function HowItWorks() {
    return (
        <>
            <Header />
            <section className="how-it-works">
                <h2 className="section-title">Our Purpose & How This Site Works</h2>
                <div className="how-it-works-content">
                    <div className="intro">
                        <h3>Welcome to Know Your Influencer</h3>
                        <p>
                            Our mission is to bring transparency and trust to the influencer world by providing a community-driven platform for genuine feedback. Discover, rate, and share your experiences with influencers across a variety of domains.
                        </p>
                    </div>
                    <div className="steps">
                        <div className="step">
                            <h3>1. Join the Community</h3>
                            <p>Register to be part of a community dedicated to honest reviews and authentic experiences.</p>
                        </div>
                        <div className="step">
                            <h3>2. Share Your Experience</h3>
                            <p>Leave impactful ratings and feedback on influencers to help others make informed decisions.</p>
                        </div>
                        <div className="step">
                            <h3>3. Discover & Decide</h3>
                            <p>Compare ratings, read reviews, and find the influencers who align with your values.</p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default HowItWorks;
