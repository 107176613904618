
import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { Card } from "antd";
import "../style/trendingInfluencer.css";

const TrendingInfluencer = () => {
    const [trendingInfluencers, setTrendingInfluencers] = useState([]);
    const [showTrending, setShowTrending] = useState(false);
    const scrollContainerRef = useRef(null);

    useEffect(() => {
        fetchTrendingDataInfluencer();
    }, []);

    const fetchTrendingDataInfluencer = async () => {
        try {
            const response = await axios.get("/api/trending/influencer");
            setTrendingInfluencers(response.data);
        } catch (error) {
            console.error("Error fetching trending data:", error);
        }
    };



    return (
        <div style={{ display: 'flex', justifyContent: "center", flexDirection: 'column' }} className="trending-container">
            <span className="trending-button" onClick={() => setShowTrending(!showTrending)}>
                {showTrending ? "Hide Trending" : "Show Trending Influencers"}
            </span>

            {showTrending && (

                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }} className="trending-section">
                    <h4>Trending Influencers</h4>


                    <div style={{ display: "flex", flexDirection: 'row' }}>
                        <div style={{
                            display: 'flex', flexDirection: 'row',
                            flexWrap: 'wrap'

                        }} ref={scrollContainerRef} className="trending-list">

                            {trendingInfluencers.map((influencer) => (
                                <Card key={influencer._id} className="custom-card">
                                    <div >
                                        <img className="influencer-img" src={influencer.pic || "placeholder.png"} alt="Influencer" />
                                        <div className="influencer-name">{influencer.name}</div>
                                        <div >Score: {influencer.contributionScore}</div>
                                        <div >{influencer.expertise}</div>
                                        <div >Rating: {influencer.averageRating}</div>
                                    </div>
                                </Card>
                            ))}
                        </div>


                    </div>
                </div></div>

            )}
        </div>
    );
};

export default TrendingInfluencer;
