


import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';  // Import useHistory for redirect
import { jwtDecode } from 'jwt-decode';
import Header from '../component/Header';
import Footer from '../component/Footer';
import AboutPlatform from '../component/AboutPlatform';
import HomeGod from './homeGod';
import axios from 'axios';


const Home = () => {
    const history = useHistory();  // Initialize history for redirect
    const [isAuthorized, setIsAuthorized] = useState(true);
    const [loggedUserName, setLoggedUserName] = useState(null);

    const [flag, setFlag] = useState(true);


    useEffect(() => {
        const token = localStorage.getItem('token');
        const loggedOut = localStorage.getItem('loggedOut'); // Check if user has logged out before

        if (!token) return;

        if (loggedOut) {
            setIsAuthorized(false);  // User has logged out before, prevent redirection to login
        } else if (!token) {
            logOut();  // Log out if token is not found
        } else {
            const decodedToken = jwtDecode(token);
            fetchLoggedUser(decodedToken.id);
        }


        let val = 0;

        const intervalId = setInterval(() => {
            const token = localStorage.getItem('token');
            if (token) {
                const decodedToken = jwtDecode(token);
                fetchLoggedUser(decodedToken.id);
            } else if (val === 0) {
                val = 1;
                logOut();  // Log out if token is not found
            }
        }, 1000);  // 20 seconds interval

        return () => clearInterval(intervalId);



        // Cleanup function to clear the interval on component unmount


    }, [history]);  // Only run once on mount

    const fetchLoggedUser = async (id) => {
        try {
            const response = await axios.get(`/api/user/${id}`);
            if (response.data.success === true) {
                setLoggedUserName(response.data.user.name);
            } else {
                // logOut();  // Log out if the user is not found
            }
        } catch (error) {

            console.log("Error fetching current logged user: ", error);
            logOut();  // Log out if there is an error fetching the user

        }
    };



    const logOut = () => {

        axios.get('/api/logout')
            .then(() => {
                localStorage.removeItem('token');
                localStorage.setItem('loggedOut', 'true');  // Store logged-out state in localStorage
                setIsAuthorized(false);  // Update the authorization state to false
                setLoggedUserName(null);  // Clear the username after logout
                // history.push('/signin');  // Redirect to the login page
                window.location.reload();

            })
            .catch(error => console.log(error));

    };

    return (
        <>
            <Header />

            <AboutPlatform />
            <HomeGod />
            <Footer />
        </>
    );
};

export default Home;
