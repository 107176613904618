
import React, { useState } from 'react';
import { useParams, useLocation, useHistory } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../style/starRating.css';
import Header from './Header';



const AskQuestions = () => {
    const { expertId } = useParams();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const expertName = queryParams.get('askedToName');
    const userName = queryParams.get('userName');
    const prodCategory = queryParams.get('prodCategory');
    const currentUserId = queryParams.get('currentUserId');
    const history = useHistory();

    const [questionTitle, setQuestionTitle] = useState('');
    const [questionDetails, setQuestionDetails] = useState('');
    const [loading, setLoading] = useState(false);
    const [rating, setRating] = useState(0); // Ensure rating is initialized

    const [influencers, setInfluencers] = useState([]);

    const updateRating = (influencerId, newRating) => {
        setInfluencers(prevInfluencers =>
            prevInfluencers.map(influencer =>
                influencer.id === influencerId ? { ...influencer, rating: newRating } : influencer
            )
        );
    };



    const handleRating = (rate) => {
        setRating(rate);
    };


    const handleQuestionSubmit = async (e) => {
        e.preventDefault();
        if (rating === 0) {
            toast.error('Please select a rating before submitting.');
            return; // Prevent submission if no rating is selected
        }
        setLoading(true);

        const token = localStorage.getItem('token');
        const payload = {
            expertId,
            expertName,
            currentUserId, // Make sure this is defined correctly
            questionTitle,
            questionDetails,
            prodCategory,
            userName,
            rating
        };

        // console.log('Payload to be sent:', payload);

        const increaseUserScore = async () => {

            const response = await axios.post('/api/increaseUserScore', payload, {

                headers: {
                    "Authorization": `Bearer ${token}`,
                    "val": 300
                }

            });
            console.log(response);
        }

        if (!token || userName == null) {
            toast.error('Please Log in or sign up!');
            return;
        }

        try {
            const rating_update = await axios.post('/api/questions', payload, {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });

            // Check for success response properly
            if (rating_update.data.success === false) {
                toast.error(rating_update.data.msg); // Use the error message from the backend
            } else {
                toast.success('Rating submitted successfully!');
                toast.success('Wow You gained 100 Points');
                // history.push('/'); // Redirect to homepage or desired page
                updateRating(expertId, rating);  // Update the rating in the parent component
                increaseUserScore();

            }

        } catch (error) {
            console.error('Error submitting Rating: ', error);
            toast.error('Failed to submit Rating.');
        } finally {
            setLoading(false);
        }
    };


    return (

        <>

            <Header />

            <div className="container main-container">
                <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' }}>
                    <h3 style={{ marginTop: '5px' }}>Rate the influencer</h3>
                    <h3 style={{ marginBottom: '5px' }}>Name: {expertName}</h3>
                </div>

                <div style={{ fontSize: '2.5rem' }} className="star-rating">
                    {/* <p>Authen</p> */}
                    {[1, 2, 3, 4, 5].map((star) => (
                        <span

                            key={star}
                            className={`star ${rating >= star ? 'filled' : ''}`}
                            onClick={() => handleRating(star)}
                        >
                            ★
                        </span>
                    ))}
                    <p>Your rating: {rating}</p>
                </div>

                <form onSubmit={handleQuestionSubmit}>
                    <div className="form-group">
                        <label>Title:</label>
                        <input
                            type="text"
                            className="form-control"
                            value={questionTitle}
                            onChange={(e) => setQuestionTitle(e.target.value)}
                            required
                        />
                    </div>
                    <div className="form-group">
                        <label>Details:</label>
                        <textarea
                            className="form-control"
                            value={questionDetails}
                            onChange={(e) => setQuestionDetails(e.target.value)}
                            required
                        />
                    </div>
                    <button type="submit" className="btn btn-primary" disabled={loading}>
                        {loading ? 'Submitting...' : 'Submit'}
                    </button>
                </form>
                {/* <AK /> */}
            </div >
        </>

    );
};

export default AskQuestions;
