


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import '../../style/userDashboard.css'; // Import your CSS file for UserDashboard styling

import Header from '../../component/Header';
import Footer from '../../component/Footer';

const UserDashboard = () => {
    const [profile, setProfile] = useState({});
    const [myQuestions, setMyQuestions] = useState([]);

    const [answersToMyQuestions, setAnswersToMyQuestions] = useState([]);
    const [questionsForExpert, setQuestionsForExpert] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const questionsPerPage = 10; // Number of questions per page

    useEffect(() => {
        fetchProfile();
    }, []);





    useEffect(() => {
        if (profile._id) {
            // fetchMyQuestions();
            // fetchAnswersToMyQuestions();
            if (profile.role === 2) {
                // fetchQuestionsForExpert();
            }
        }
    }, [profile]);

    const fetchProfile = async () => {
        try {
            const response = await axios.get('/api/getme');
            setProfile(response.data.user);
        } catch (error) {
            toast.error('Failed to fetch profile.');
        }
    };

    const totalScore = profile.contributionScore;
    // Function to handle page change
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    return (
        <>
            <Header />
            <div className="container user-dashboard">
                {/* <h2>User Dashboard</h2> */}
                <div style={{
                    display: 'flex', justifyContent: 'center'
                }}>

                    <h2>Profile</h2>
                </div>

                <div className="card mb-3">
                    <div className="card-header">
                        <h4 style={{ color: 'blue' }}>User Information</h4>
                    </div>
                    <div className="card-body">

                        <li >Name: {profile.name}</li>
                        <li >Email: {profile.email}</li>
                        {/* <li >Role: {profile.role === 1 ? 'Admin' : (profile.role === 2 ? 'Expert' : 'Registered User')}</li> */}
                        <li >Joined at: {new Date(profile.createdAt).toLocaleDateString()}</li>
                        {/* <li >Details: {profile.details}</li> */}


                    </div>
                </div>
                {/* Announcement Section */}
                {/* <div className="card mb-3">
                    <div style={{ backgroundColor: 'white' }} className="card-header">
                        <h4 style={{ color: 'red', backgroundColor: 'white' }}>Exciting News!</h4>
                    </div>
                    <div className="card-body">
                        <p>
                            We are working on an exciting new feature to bring a scoring system and badges based on your
                            contributions on rate , like and also  the likes and comments you receive.
                            <p>Your participation means a lot to us
                                at <strong>KnowYourInfluencer.com</strong>. Stay tuned for updates, and thank you for being an
                                integral part of our community!</p>
                        </p>
                    </div>
                </div> */}
                <div className="card mb-3">

                    <div className="card-body">

                        <h4 style={{ color: 'blue' }}> Total Score🔥: <span style={{ color: 'red' }}>{totalScore} </span></h4>


                        {totalScore > 0 ? (
                            <p>🎉 Great job! Keep up the good work!</p>
                        ) : (
                            <p>📢 No contributions yet! Start engaging with posts to see your activity here.</p>
                        )}

                        <p> Note : The score is generated based on your reviews, comments, likes, and dislikes. </p>
                    </div>
                </div>

            </div >
            <Footer />
        </>
    );
};

export default UserDashboard;

