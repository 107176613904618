import { Link, useHistory } from 'react-router-dom';
import React from 'react';
import '../style/footersection.css';

const Footer = () => {
    return (
        <footer id="contact-section">
            <div className="footer-container">
                <div className="footer-links">
                    <div className="link-to-footer">
                        <h4 >About</h4>
                        <ul>
                            <li className="nav-item">
                                <Link className="nav-link" to="/ourPurpose"> Our purpose, Mission, and Values </Link>
                            </li>
                            <li><a href="#">Privacy Policy</a></li>
                            <li><a href="#">Terms of Service</a></li>
                        </ul>
                    </div>
                    <div className="link-to-footer">
                        <h4>Contact</h4>
                        <ul>
                            {/* <li><a href="https://wa.me/1234567890" target="_blank"><i className="fab fa-whatsapp"></i> WhatsApp</a></li> */}

                            <li><a href="mailto:info.knowyourinfluencer@gmail.com" target="_blank">📩 info.knowyourinfluencer@gmail.com</a></li>

                        </ul>
                    </div>
                    <div className="link-to-footer">
                        <h4>Follow Us</h4>
                        <ul className="social-links">
                            <li><a href="https://x.com/InfluenceCheck" target="_blank"><i className="fab fa-twitter"></i> Twitter</a></li>
                            {/* <li><a href="https://facebook.com" target="_blank"><i className="fab fa-facebook"></i> Facebook</a></li> */}
                        </ul>
                    </div>
                </div>
                <div className="footer-bottom">
                    <p>&copy; 2024 Know Your Influencer. All rights reserved.</p>
                </div>
            </div>
        </footer>
    );
}

export default Footer;
