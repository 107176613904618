
import React, { useEffect, useState, useRef, useCallback } from 'react';
import axios from 'axios';
import Card from '../component/Card';
import 'antd/dist/antd.css';
import { Redirect } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { useUserContext } from '../userContext';
import { Spin } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import '../style/homeGod.css';

import TrendingInfluencer from './TrendingInfluencer'


import SearchBox from './searchbox';

const HomeGod = () => {

    // useScrollPosition(); // Add this line


    const { users, setUsers, selectedCategory, setSelectedCategory } = useUserContext();

    const [userCategories, setUserCategories] = useState([]);
    const [currentUserId, setCurrentUserId] = useState(null);
    const [activeCategory, setActiveCategory] = useState(selectedCategory || '');
    const [isAuthorized, setIsAuthorized] = useState(true);
    const [loggedUserName, setLoggedUserName] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [skip, setSkip] = useState(0);

    const categoryRowRef = useRef(null);

    // Combine influencers with users for search results
    const [influencers, setInfluencers] = useState([]);

    const handleSearchResults = (results) => {
        setInfluencers(results); // Store search results separately
    };


    const fetchCategories = async () => {
        try {
            const response = await axios.get('/api/category/all');
            setUserCategories(response.data.categories);
        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };





    //postion saving for back navigation 
    useEffect(() => {
        const savedScrollPosition = localStorage.getItem('scrollPosition');
        if (savedScrollPosition) {
            window.scrollTo(0, parseInt(savedScrollPosition, 10));
        }

        return () => {
            // Optionally, remove scroll position from localStorage when leaving HomeGod
            localStorage.removeItem('scrollPosition');
        };
    }, []);

    // Set a flag when "Rating" or "Comments" is clicked
    const handleRatingOrCommentsClick = () => {
        sessionStorage.setItem('skipSaveScrollPosition', 'true');
    };

    //postion saving for back navigation 

    useEffect(async () => {
        const token = localStorage.getItem('token');
        if (!token) {
            setIsAuthorized(false);
        } else {
            const decodedToken = jwtDecode(token);
            fetchLoggedUser(decodedToken.id);
            setCurrentUserId(decodedToken.id);
        }
        await fetchCategories();
    }, []);



    const fetchLoggedUser = async (id) => {
        try {
            const response = await axios.get(`/api/user/${id}`);
            if (response.data.success === true) {
                setLoggedUserName(response.data.user.name);
            }
        } catch (error) {
            console.log("Error fetching current logged user: ", error);
        }
    };

    const fetchUsers = useCallback(async () => {
        setLoading(true);
        try {
            const response = await axios.get("/api/users", {
                params: {
                    skip,
                    category: activeCategory
                }
            });

            let newUsers = response.data;

            setUsers((prevUsers) => {
                const allUsers = [...prevUsers, ...newUsers.flatMap((item) => item.users || item)];
                const uniqueUsers = [...new Map(allUsers.map(user => [user._id, user])).values()];


                return uniqueUsers;
            });

            setHasMore(newUsers.length === 10);
        } catch (error) {
            console.error('Error fetching users:', error);
        } finally {
            setLoading(false);
        }
    }, [skip, activeCategory]);

    const handleCategoryChange = (newCategory) => {

        if (newCategory === activeCategory) {


            setActiveCategory(newCategory);
            setSelectedCategory(newCategory);
            setUsers([]);
            setSkip(0);
            fetchUsers();
            return;
        }


        setActiveCategory(newCategory);
        setSelectedCategory(newCategory);
        setUsers([]);
        setSkip(0);
    };

    useEffect(() => {
        if (activeCategory !== null) fetchUsers();
    }, [activeCategory, skip]);






    // Save scroll position on unmount
    useEffect(() => {
        const saveScrollPosition = () => {
            sessionStorage.setItem('scrollPosition', window.scrollY.toString());
        };

        window.addEventListener('beforeunload', saveScrollPosition);
        return () => {
            window.removeEventListener('beforeunload', saveScrollPosition);
        };
    }, []);


    const scrollCategories = (direction) => {
        if (categoryRowRef.current) {
            const scrollAmount = direction === 'left' ? -300 : 300;
            categoryRowRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
        }
    };




    const handleScroll = useCallback(() => {
        if (window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 && hasMore && !loading) {
            setSkip(prevSkip => prevSkip + 10);
        }
    }, [hasMore, loading]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    const logOut = () => {
        axios.get('/api/logout')
            .then(() => {
                toast.success("Please login again, token expired", {
                    autoClose: 1500,
                    style: { fontSize: '14px', padding: '10px 15px', top: '20px', left: '50%', transform: 'translateX(-50%)' }
                });
                localStorage.removeItem('token');
                setIsAuthorized(false);
            })
            .catch(error => console.log(error));
    };

    // if (!isAuthorized) {
    //     <Redirect to="/" />;
    // }
    const [activeComponent, setActiveComponent] = useState(null);

    const handleClick = (component) => {
        // If clicking the same component again, show both
        if (activeComponent === component) {

            setActiveComponent(null);
        } else {
            setActiveComponent(component);
        }
    };


    return (
        (

            <div id='expert-section' className="container ">


                <SearchBox onResults={handleSearchResults} />

                <div className="col-sm-12">




                    <mypiv
                        style={{
                            display: "flex",
                            flexDirection: "row", // Row for desktop, column for mobile
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "5px", // Space between sections
                            paddingTop: "5x",

                        }}
                    >
                        {/* Influencer Section */}
                        <m
                            onClick={() => handleClick("influencer")}
                            style={{
                                cursor: "pointer",
                                // flex: 1,
                                textAlign: "center",
                                transition: "all 0.3s ease-in-out",
                            }}
                        >
                            {/* {(activeComponent === null || activeComponent === "influencer") && <TrendingInfluencer />}
                             */}

                            <div style={{ width: '90vw', overflow: 'hidden' }}>

                                <  TrendingInfluencer />

                            </div>

                        </m>

                        {/* Users Section */}
                        <div
                            onClick={() => handleClick("users")}
                            style={{
                                cursor: "pointer",
                                // flex: 1,
                                textAlign: "center",
                                transition: "all 0.3s ease-in-out",
                            }}
                        >
                            {/* {(activeComponent === null || activeComponent === "users") && <TrendingUsers />} */}
                            {/* <TrendingUsers /> */}
                        </div>
                    </mypiv>





                    <div className="categories-container">
                        <button className="scroll-arrow left-arrow" onClick={() => scrollCategories('left')}>
                            <LeftOutlined />
                        </button>
                        <div style={{ width: '100vw' }} className="categories-row-horizontal" ref={categoryRowRef}>
                            <button
                                className={`category-button ${activeCategory === '' ? 'selected' : ''}`}
                                onClick={() => handleCategoryChange('')}

                            >
                                All
                            </button >

                            {userCategories.map(cat => (
                                <button
                                    key={cat._id}
                                    className={`category-button ${activeCategory === cat.name ? 'selected' : ''}`}
                                    onClick={() => handleCategoryChange(cat.name)}

                                >
                                    {cat.name}
                                </button>
                            ))}



                        </div>


                        <button className="scroll-arrow right-arrow" onClick={() => scrollCategories('right')}>
                            <RightOutlined />
                        </button>
                    </div>


                    <div className="row">
                        {((influencers.length > 0) ? influencers : users).map(user => (
                            <Card
                                key={user._id}
                                image={user.pic}
                                productName={user.name}
                                expertId={user._id}
                                expertName={user.name}
                                prodCategory={user.category}
                                expertise={user.expertise}
                                email={user.email}
                                price={user.hourlyRate}
                                currentUserId={currentUserId}
                                userName={loggedUserName}
                                rating={user.averageRating}
                                reviewsCount={user.reviewsCount}
                                onCommentsClick={handleRatingOrCommentsClick} // Add this to detect clicks
                            />
                        ))}
                    </div>



                    {loading && (
                        <div className="loading-spinner">
                            <Spin size="large" />
                        </div>
                    )}
                </div>

            </div >
        )
    );
};

export default HomeGod;

