import React, { useEffect } from 'react';
import './style/style.css';
import Home from './pages/Home';
import HowItWorks from './component/HowItWorks';
import rate from './component/rate';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import ForgotPassword from './pages/forgetpassword';
import ResetPassword from './pages/resetpassword';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import PrivateRoute from './component/PrivateRoute';


import AdminDashboard from './pages/admin/AdminDashboard';
import SignUpExpert from './pages/expertSignup';
import OurPurpose from './component/ourPurpose'
import allReviews from './component/allReviews'
import UserDashboard from './pages/user/UserDashboard';
import TopContributor from './component/TopContributor';



const App = () => {


  return (
    <>
      <ToastContainer />
      <Switch>
        {/* Home page is accessible without authentication */}
        <Route exact path="/" component={Home} />
        {/* <Route path="/" exact component={HomeGod} /> */}
        <Route exact path="/signup" component={SignUp} />
        <Route exact path="/signup/expert" component={SignUpExpert} />
        <Route exact path="/signin" component={SignIn} />
        <Route exact path="/allReviews" component={allReviews} />
        {/* Protect routes that require authentication */}
        <PrivateRoute exact path="/user/dashboard" component={UserDashboard} />
        <PrivateRoute exact path="/admin/dashboard" component={AdminDashboard} />


        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/api/reset-password" component={ResetPassword} />
        <Route path="/ask-questions/:expertId" component={rate} />
        <Route path="/reviews/:expertId" component={allReviews} />

        <Route path="/HowItWorks" component={HowItWorks} />
        <Route path="/TopContributor" component={TopContributor} />
        <Route path="/ourPurpose" component={OurPurpose} />
      </Switch>
    </>
  );
};

const RootApp = () => (
  <BrowserRouter>
    <App />
  </BrowserRouter>
);

export default RootApp;
