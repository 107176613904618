


import React, { useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import Header from '../component/Header';
import Footer from '../component/Footer';
import { Link, useHistory } from 'react-router-dom';
import '../style/signin.css'




const SignIn = () => {
    const [values, setValues] = useState({
        email: '',
        password: '',
        showPassword: false
    });

    const { email, password, showPassword } = values;

    const [signInError, setSignInError] = useState('');
    const history = useHistory();

    const handleChange = name => (e) => {
        setValues({ ...values, [name]: e.target.value });
    }

    const togglePasswordVisibility = () => {
        setValues({ ...values, showPassword: !showPassword });
    }

    const handleSubmit = async (e) => {

        e.preventDefault();
        try {
            const { data } = await axios.post('/api/signin', {
                email,
                password
            });

            if (data.success === true) {
                setValues({ ...values, email: '', password: '' });
                // toast.success("Log In successfully", { autoClose: 1500 });?
                toast.success("Log In successfully", {
                    autoClose: 1500,
                    style: {
                        fontSize: '14px',        // Adjust font size
                        padding: '10px 15px',   // Adjust padding
                        marginTop: '10px',       // Add top margin
                        position: 'absolute',     // Ensure it doesn't push content
                        top: '20px',              // Adjust top position
                        left: '50%',              // Center it horizontally
                        transform: 'translateX(-50%)', // Centering adjustment
                    }
                });

                localStorage.setItem("token", JSON.stringify(data));
                history.push('/');
                setTimeout(() => {
                    // window.location.reload();

                }, 1500);

            }
        } catch (err) {
            toast.error(err.response.data.error);
            setSignInError(err.response.data.error);
            // toast.error(err.response.data.error, {
            //     autoClose: 2000, // Set the toast to disappear after 2 seconds
            // });
        }
    };

    return (
        <div >
            <Header />
            <div style={{ backgroundColor: '#F4F4F4' }} className="signin-container">
                <form className="signin-form" onSubmit={handleSubmit}>
                    <div className="signin-title">Log In</div>
                    <div className="form-group">
                        <input
                            onChange={handleChange("email")}
                            type="email"
                            id="email"
                            className="form-control"
                            value={email}
                            placeholder="Email"
                            required
                        />
                    </div>
                    <div className="form-group">
                        <input
                            onChange={handleChange("password")}
                            type={showPassword ? "text" : "password"}
                            id="password"
                            className="form-control"
                            value={password}
                            placeholder="Password"
                            required
                        />
                        <button
                            type="button"
                            className="toggle-password-btn"
                            onClick={togglePasswordVisibility}
                        >
                            {showPassword ? "Hide" : "Show"}
                        </button>
                    </div>



                    {/* <button type="submit" className="btn-primary ">Log In</button> */}

                    <p style={{ color: 'red' }}>{signInError}</p>
                    <div className="d-flex justify-content-center">
                        <button type="submit" className="btn btn-primary">Log In</button>
                    </div>


                    <Link to="/forgot-password">Forgot Password?</Link>
                </form>
            </div>
            <hr></hr>
            <br></br>
            <br></br>
            <Footer />

        </div>
    )
}

export default SignIn;
